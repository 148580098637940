





































































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import {
  SfSelect,
  SfButton,
} from '@storefront-ui/vue';
import { useUiHelpers, useUiState } from '@wemade-vsf/composables';
import type { Pagination } from '@wemade-vsf/core';
import SkeletonLoader from 'components/theme/SkeletonLoader.vue';
import { SortingModel } from '@wemade-vsf/catalog/lib/runtime/category/composables/useFacet/sortingOptions';

export default defineComponent({
  components: {
    SkeletonLoader,
    SfSelect,
    SfButton,
  },
  props: {
    sortBy: {
      type: Object as PropType<SortingModel>,
      required: true,
    },
    pagination: {
      type: Object as PropType<Pagination>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const {
      toggleFilterSidebar, changeToCategoryListView, changeToCategoryGridView, isCategoryGridView,
    } = useUiState();
    const uiHelpers = useUiHelpers();
    const doChangeSorting = (sort: string) => {
      uiHelpers.changeSorting(sort, false);
      emit('reloadProducts');
    };
    return {
      toggleFilterSidebar,
      doChangeSorting,
      ...uiHelpers,
      changeToCategoryListView,
      changeToCategoryGridView,
      isCategoryGridView,
    };
  },
});
